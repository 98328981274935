import React from "react";
import BreadCrumb from "../components/Breadcrumb/BreadCrumb";
import AboutCompany from "../components/Terms/AboutCompany";
import InnerPageLayout from "../components/Layout/InnerPageLayout";

export default function PrivacyPolicy() {
  return (
    <InnerPageLayout>
      <BreadCrumb pageTitle="Terms & Service" currentPage="Terms & Service" to="/terms" />
      <AboutCompany />
    </InnerPageLayout>
  );
}
