import React from "react";

export default function AboutCompany() {
  return (
    <section className="w-full pb-16">
      <div className="w-full mx-auto theme-container">
        <div className="mt-8 space-y-4 text-white">
                <h2 className="text-3xl font-bold text-gray-900">Privacy Policy</h2>
                <p className="font-medium">Effective Date: January 1, 2025</p>

                <div className="space-y-4">
                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">1. Introduction</h3>
                    <p>Welcome to OBSRVR. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our ASIC miner monitoring platform (the "Service").</p>
                  </section>
                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">2. Information We Collect</h3>
                    <p>We collect different types of information to provide and improve our Service, including:</p>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li><span className="font-medium">Account Information:</span> When you create an account, we collect your name, email address, and other necessary credentials.</li>
                      <li><span className="font-medium">Miner Data:</span> We collect and process data from your ASIC miners, including performance metrics, logs, and alerts.</li>
                      <li><span className="font-medium">Usage Data:</span> We collect data related to your interactions with our platform, such as login history, IP addresses, and preferences.</li>
                      <li><span className="font-medium">Device and Network Information:</span> We may collect information about your device, browser, and network for security and analytics purposes.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">3. How We Use Your Information</h3>
                    <p>We use the collected information to:</p>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>Provide, maintain, and improve the Service.</li>
                      <li>Monitor miner performance and generate reports.</li>
                      <li>Enhance security and detect fraudulent activities.</li>
                      <li>Communicate with users regarding service updates and support.</li>
                      <li>Comply with legal obligations.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">4. Sharing of Information</h3>
                    <p>We do not sell your information. We may share your data with:</p>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li><span className="font-medium">Service Providers:</span> Third-party vendors who assist with analytics, cloud storage, and security.</li>
                      <li><span className="font-medium">Legal Authorities:</span> When required by law or to protect our rights and security.</li>
                      <li><span className="font-medium">Business Transfers:</span> In case of a merger, acquisition, or sale of assets, your information may be transferred.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">5. Data Security</h3>
                    <p>We implement appropriate security measures to protect your data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is 100% secure.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">6. Data Retention</h3>
                    <p>We retain your information as long as necessary for business, legal, or compliance purposes. You may request data deletion by contacting us.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">7. Your Rights</h3>
                    <p>Depending on your location, you may have rights to:</p>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>Access, correct, or delete your personal data.</li>
                      <li>Object to or restrict data processing.</li>
                      <li>Withdraw consent where applicable.</li>
                    </ul>
                    <p className="mt-2">To exercise these rights, contact us at support@obsrvr.io</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">8. Cookies and Tracking Technologies</h3>
                    <p>We use cookies and similar tracking technologies to enhance user experience and analyze platform usage. You can control cookie preferences through your browser settings.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">9. Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated effective date.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">10. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                    <div className="mt-2">
                      <p className="font-medium">OBSRVR</p>
                      <p>Email: support@obsrvr.io</p>
                      <p>Website: www.obsrvr.io</p>
                    </div>
                  </section>
                </div>
              </div>
      </div>
    </section>
  );
}
