import React from "react";

export default function ServiceThree() {
  return (
    <section id="services">
      <div className="w-full what-we-do-wrapper pb-16 md:pb-[130px] relative overflow-x-hidden">
        <div className="mx-auto theme-container">
          <div className="w-full">
            <div className="flex justify-center w-full title-area">
              <div className="flex flex-col items-center">
                <div className="mb-[70px]">
                  <h2 className="font-semibold text-center text-white text-24 sm:text-48">
                    The observability you deserve.

                  </h2>
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
              <div
                data-aos="fade-left"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-7">
                  <img
                    src="/assets/images/real-time-icon.png"
                    alt=""
                    className="relative z-10 w-14"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Real-time monitoring
                </h1>
                <p className="text-white opacity-55">
                  Monitor your mining operations in real-time, ensuring optimal performance and efficiency.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="100"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-[28px]">
                  <img
                    src="/assets/images/inventory-white.png"
                    alt=""
                    className="relative z-10 w-14"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Inventory management
                </h1>
                <p className="text-white opacity-55">
                  Track and manage your mining hardware inventory efficiently, ensuring optimal resource allocation and maintenance schedules.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="200"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-[28px]">
                  <img
                    src="/assets/images/alerting.png"
                    alt=""
                    className="relative z-10 w-16"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Monitoring & alerting
                </h1>
                <p className="text-white opacity-55">
                  Configure and customize monitoring and alerting systems to meet your specific needs, ensuring timely responses to critical events.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="300"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-[28px]">
                  <img
                    src="/assets/images/power.png"
                    alt=""
                    className="relative z-10 w-14"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Power management
                </h1>
                <p className="text-white opacity-55">
                  Control your mining rigs remotely on a schedule or manually, ensuring optimal power usage and reducing operational costs.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="400"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-[28px]">
                  <img
                    src="/assets/images/access.png"
                    alt=""
                    className="relative z-10 w-14"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Role-based access control
                </h1>
                <p className="text-white opacity-55">
                    Assign different roles and permissions to users, ensuring secure and controlled access to sensitive information and features.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="500"
                className="service-item p-5 md:p-[50px] relative group"
              >
                <div className="service-item-ico w-[80px] h-[80px] rounded-[10px] flex justify-center items-center mb-[28px] p-3">
                  <img
                    src="/assets/images/log-data.png"
                    alt=""
                    className="relative z-10"
                  />
                </div>
                <h1 className="mb-5 font-medium text-white">
                  Miner activity & state logs
                </h1>
                <p className="text-white opacity-55">
                Track real-time and historical miner activity with detailed state logs. Gain insights into performance, detect anomalies, and troubleshoot issues efficiently with a complete operational history.
                </p>
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition duration-300 ease-in-out opacity-0 circle-shape group-hover:opacity-100">
                  <img
                    src="/assets/images/home-four/service-circle-shape.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute z-10 shape-1 left-40 top-96">
          <img src="/assets/images/home-four/shape-1.webp" alt="" />
        </div>
        <div className="absolute z-10 shape-2 right-96 top-96">
          <img src="/assets/images/home-four/shape-2.webp" alt="" />
        </div>
        <div className="shape-3 absolute left-96 top-[550px] z-10">
          <img src="/assets/images/home-four/shape-3.webp" alt="" />
        </div>
        <div className="shape-4 absolute right-96 top-[550px] z-10">
          <img src="/assets/images/home-four/shape-4.webp" alt="" />
        </div> */}
      </div>
    </section>
  );
}
