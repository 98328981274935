import React from "react";

export default function AboutCompany() {
  return (
    <section className="w-full pb-16">
      <div className="w-full mx-auto theme-container">
        <div className="mt-8 space-y-4 text-white">
                <h2 className="text-3xl font-bold text-white">Terms and Conditions</h2>
                <p className="font-medium">Effective Date: January 1, 2025</p>

                <div className="space-y-4">
                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">1. Introduction</h3>
                    <p>Welcome to OBSRVR. These Terms and Conditions ("Terms") govern your access to and use of our ASIC miner monitoring platform (the "Service"). By accessing or using the Service, you agree to comply with these Terms.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">2. Definitions</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li><span className="font-medium">"Company," "we," "us," or "our"</span> refers to OBSRVR.</li>
                      <li><span className="font-medium">"User," "you," or "your"</span> refers to any person or entity using the Service.</li>
                      <li><span className="font-medium">"Service"</span> refers to the ASIC miner monitoring platform provided by OBSRVR.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">3. Use of the Service</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>You must be at least 18 years old to use the Service.</li>
                      <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                      <li>You agree not to use the Service for any unlawful or prohibited purpose.</li>
                      <li>Unauthorized access to the Service is strictly prohibited.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">4. Subscription and Payment</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>Certain features of the Service may require a subscription.</li>
                      <li>Subscription fees are billed on a recurring basis as specified at the time of purchase.</li>
                      <li>All payments are non-refundable unless otherwise stated.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">5. User Responsibilities</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>You are responsible for the accuracy and legality of the data you provide.</li>
                      <li>You shall not use the Service to distribute malware, spam, or other harmful content.</li>
                      <li>You agree not to interfere with the security or integrity of the Service.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">6. Intellectual Property</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>All intellectual property rights in the Service belong to OBSRVR.</li>
                      <li>You may not reproduce, modify, or distribute any part of the Service without our permission.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">7. Termination</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>We reserve the right to suspend or terminate your access to the Service for any violation of these Terms.</li>
                      <li>Upon termination, your access to the Service and associated data may be revoked.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">8. Limitation of Liability</h3>
                    <ul className="ml-6 mt-2 list-disc space-y-2">
                      <li>The Service is provided "as is" and "as available" without warranties of any kind.</li>
                      <li>OBSRVR is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of the Service.</li>
                    </ul>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">9. Indemnification</h3>
                    <p>You agree to indemnify and hold OBSRVR harmless from any claims arising from your use of the Service or violation of these Terms.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">10. Governing Law</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of the United States. Any disputes arising from these Terms shall be resolved in the courts of the United States.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">11. Changes to Terms</h3>
                    <p>We reserve the right to update these Terms at any time. Updates will be posted with the revised effective date.</p>
                  </section>

                  <section>
                    <h3 className="mb-3 text-xl font-semibold text-white">12. Contact Us</h3>
                    <p>If you have any questions regarding these Terms, please contact us at:</p>
                    <div className="mt-2">
                      <p className="font-medium">OBSRVR</p>
                      <p>Email: support@obsrvr.io</p>
                      <p>Website: www.obsrvr.io</p>
                    </div>
                  </section>
                </div>
        </div>
      </div>
    </section>
  );
}
