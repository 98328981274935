import React from "react";
import DemoHeaderOne from "./Header/DemoHeader/DemoHeaderOne";
import FooterFour from "./Footer/FooterFour";
import MobileHeader from "./Header/DemoHeader/DemoHeaderMobile";
import LandingHeaderFour from "../Layout/Header/LandingHeader/LandingHeaderFour";

export default function InnerPageLayout({ children }) {
  return (
    <div>
      <LandingHeaderFour />
      <MobileHeader />
      {children}
      <FooterFour />
    </div>
  );
}
