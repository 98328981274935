import React from 'react'

export default function FeatureTwo() {
  return (
    <section id="features">
      <div className="feature-section-wrapper w-full py-16 md:py-[130px] mt-8 relative overflow-x-hidden">
        <div className="mx-auto theme-container">
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-10 xl:gap-[140px] items-center">
            <div className="w-full">
              <div className="mb-5 section-title-top-tag-two">
                <span>Our features</span>
              </div>
              <div className="mb-[50px]">
                <h2 className="font-semibold text-white text-24 sm:text-48">
                  Faster, Smarter, Start <br />
                  to use in few minutes
                </h2>
              </div>
              <div className="w-full">
                <div data-aos="fade-up" className="feature-item-h-4">
                  <div className="feature-item-wrapper w-full px-5 py-5 md:px-[30px] md:py-[35px] flex flex-col sm:flex-row gap-5 sm:gap-10 items-start">
                    <div className="w-[30px]">
                      <img
                        src="/assets/images/goal.png"
                        alt=""
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="mb-3 font-semibold leading-none text-white text-22">
                        Unparalleled operational visibility
                      </h3>
                      <p className="text-white opacity-50">
                        Get a complete view of your infrastructure with real-time monitoring, detailed metrics, and comprehensive logging capabilities that help you identify and resolve issues quickly.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="feature-item-h-4"
                >
                  <div className="feature-item-wrapper w-full px-5 py-5 md:px-[30px] md:py-[35px] flex flex-col sm:flex-row gap-5 sm:gap-10 items-start">
                    <div className="w-[30px]">
                      <img
                        src="/assets/images/four-squares.png"
                        alt=""
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="mb-3 font-semibold leading-none text-white text-22">
                        Effortless management
                      </h3>
                      <p className="text-white opacity-50">
                        Simplify your infrastructure management with our user-friendly interface and automated workflows that reduce manual effort and improve efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="feature-item-h-4"
                >
                  <div className="feature-item-wrapper w-full px-5 py-5 md:px-[30px] md:py-[35px] flex flex-col sm:flex-row gap-5 sm:gap-10 items-start">
                    <div className="w-[30px]">
                      <img
                        src="/assets/images/setting.png"
                        alt=""
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="mb-3 font-semibold leading-none text-white text-22">
                        Customizable Workflows
                      </h3>
                      <p className="text-white opacity-50">
                      Design and automate workflows tailored to your mining operations. Define custom processes, streamline tasks, and enhance efficiency with flexible automation that adapts to your needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-left" className="w-full">
              <div className="px-5 py-4 md:px-[2px] md:py-[2px] rounded-[20px] border border-[transparent] bg-[transparent]">
                <img
                  src="/assets/images/home-four/features-thumb.png"
                  alt=""
                  className="w-full bg-[transparent] rounded-md p-2"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute z-10 shape-1 left-40 top-96">
          <img src="/assets/images/home-four/shape-1.webp" alt="" />
        </div>
        <div className="absolute z-10 shape-2 right-96 top-96">
          <img src="/assets/images/home-four/shape-2.webp" alt="" />
        </div>
        <div className="shape-3 absolute left-96 top-[550px] z-10">
          <img src="/assets/images/home-four/shape-3.webp" alt="" />
        </div>
        <div className="shape-4 absolute right-96 top-[550px] z-10">
          <img src="/assets/images/home-four/shape-4.webp" alt="" />
        </div> */}
      </div>
    </section>
  );
}
