import React from "react";
import BreadCrumb from "../components/Breadcrumb/BreadCrumb";
import AboutCompany from "../components/PrivacyPolicy/AboutCompany";
import FunFactCounterTwo from "../components/FunFact/FunFactCounterTwo";
import AboutBenefit from "../components/PrivacyPolicy/AboutBenefit";
import AboutLogoCloud from "../components/ClientLogoSlider/AboutLogoCloud";
import AboutTestimonial from "../components/Testimonials/AboutTestimonial";
import AboutTeam from "../components/Team/AboutTeam";
import InnerPageLayout from "../components/Layout/InnerPageLayout";

export default function PrivacyPolicy() {
  return (
    <InnerPageLayout>
      <BreadCrumb pageTitle="Privacy Policy" currentPage="Privacy Policy" to="/privacy-policy" />
      <AboutCompany />
    </InnerPageLayout>
  );
}
